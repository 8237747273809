import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

export const ProductContainer = styled.div`
  ${tw`col-span-4 order-6 lg:col-span-4 lg:row-span-1 lg:order-5`};
  height: 300px;
  display: flex;
  justify-content: center;
  margin: 2em auto auto auto;
  max-width: 290px;
  position: relative;
  width: 290px;
  @media screen and (min-width: 1024px) {
    height: 340px;
    margin: 7em auto auto auto;
  }
  @media screen and (min-width: 1250px) {
    margin: 5em auto auto auto;
  }
`

export const ProductShape = styled.div`
  background: ${props => props.hovering ? theme`colors.salmon` : theme`colors.extralightgrey`};
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  cursor: pointer;
  height: 100%;
  position: absolute;
  transition: 1s;
  right: 0;
  top: 0;
  width: 100%;
  @media screen and (min-width: 1024px) {
    height: calc(100% - 4em);
    margin-top: 4em;
  }
`

export const ProductText = styled.p`
  color: ${theme`colors.royalblue`};
  cursor: pointer;
  font-size: 30px;
  font-style: italic; 
  font-weight: 100;
  position: absolute;
  text-align: center;
  top: 90px;
  width: 80%;
  left: 10%;
  @media screen and (min-width: 1024px) {
    top: 150px;
  }
`