import React from 'react'

import { Grid } from '../Blocks/Grid/styles'
import { ContactFormContainer, ContactFormTitle } from './styles'
import EnvelopeIcon from './EnvelopeIcon/envelopeIcon'
import Form from './Form/form'

const ContactForm = ({ setShowExitModal, setExitUrl, setExitModalCopy }) => {
  return(
    <ContactFormContainer id="contact-form">
      <Grid>
        <EnvelopeIcon />

        <ContactFormTitle>
          <p className="title-header">Let’s continue the conversation</p>
          <p className="title-copy">To stay in contact with us and learn more about our congress activities, please fill out the information below</p>
        </ContactFormTitle>
      </Grid>

      <Form setShowExitModal={setShowExitModal} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy}  />
    </ContactFormContainer>
  )
}

export default ContactForm