import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

export const ExitModalContainer = styled.div`
  background: rgba(235, 250, 255, 0.95); /* light blue with 95% opacity */
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
`

export const ExitModalOuter = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100vw;
`

export const ExitModalInner = styled.div`
  ${tw`col-span-4 lg:col-span-6 lg:col-span-8 lg:col-start-3`};
  background: ${theme`colors.bgRoyalBlue`};
  border-radius: 12px;
  max-width: calc(100% - 2em);
  min-height: 200px;
  padding: 1.5em 1em;
  position: relative;
  width: 100%;
  @media screen and (min-width: 550px) {
    padding: 1.5em 1em 3.5em 1em;
  }
  @media screen and (min-width: 768px) {
    max-width: 700px;
    margin: 0 auto;
  }
`

export const ExitModalWelcome = styled.p`
  color: ${theme`colors.skyblue`};
  font-size: 32px;
  font-weight: 100;
  text-align: center;
  
  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
`

export const ExitModalCopy = styled.p`
  color: ${theme`colors.white`};
  font-size: 16px;
  font-weight: 100;
  margin-top: 1em;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`

export const ExitModalDisclaimer = styled.p`
  color: ${theme`colors.skyblue`};
  font-size: 14px;
  font-weight: 100;
  margin-top: 1em;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`

export const ExitModalBtns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1em auto 0 auto;
  position: relative;
  width: calc(100% - 2em);
  @media screen and (min-width: 550px) {
    bottom: -22px;
    flex-wrap: nowrap;
    position: absolute;
  }
  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
  button {
    margin: 0.25em 0.5em;
    @media screen and (min-width: 550px) {
      margin: 0 1em;
    }
  }
`