import React from 'react'
import styled from 'styled-components/macro'
import tw, { theme } from 'twin.macro'

import { Grid } from '../../../Blocks/Grid/styles'

const PrivacyPolicyContainer = styled.div`
  ${tw`col-span-4 lg:col-span-8 lg:col-start-3`};
  background: white;
  color: ${theme`colors.lightgrey`};
  border-radius: 2px;
  margin: 1rem 0;
  padding: 1rem;
  text-align: left;
  @media screen and (min-width: 768px) {
    text-align: center;
  }

  .privacypolicy-title {
    font-size: 14px;
    font-weight: bold;
  }

  .privacypolicy-copy {
    font-size: 12px;
    margin-top: 0.6rem;
  }

  .privacypolicy-copy__anchor {
    color: ${theme`colors.orange`};
    cursor: pointer;
    text-decoration: underline;
  }
`

const PrivacyPolicy = () => {
  return(
    <Grid>
      <PrivacyPolicyContainer>
        <p className="privacypolicy-title">INFORMATION & CONSENT FOR THE PROCESSING OF PERSONAL DATA</p>
        <p className="privacypolicy-copy">For the purposes of providing you the services offered by this website, you will be requested to communicate personal information. The processing of this information is necessary to allow us to communicate with you regarding information or resources that you request, and/or to implement pharmacovigilance processing. We may share some of your personal information, the information relating to you use of this website and your interactions with the website to one or more affiliated companies or to our Alliance partner, Regeneron Pharmaceuticals Inc.</p>
        <p className="privacypolicy-copy">This information may be processed outside your jurisdiction (including where your jurisdiction is in the European Union) in countries which do not provide the same level of protection to personal data. The data we receive will generally be retained for the duration of your contractual relationship with Sanofi and in case only for the duration that is necessary to fulfil the above mentioned purposes, unless further retention is necessary to meet legal or regularity requirements or for the protection of Sanofi’s interests. The principles set out in our Privacy Policy shall apply to our processing of this information.</p>
      </PrivacyPolicyContainer>
    </Grid>
  )
}

export default PrivacyPolicy