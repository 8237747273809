import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

export const HeaderContainer = styled.div`
  align-items: center;
  background: ${theme`colors.white`};
  display: flex;
  height: 100px;
  @media screen and (min-width: 1024px) {
    background: ${theme`colors.bgLightBlue`};
  }
`

export const HeaderDisclaimer = styled.div`
  ${tw`col-span-12`};
  background: ${theme`colors.bgLightGrey`};
  font-size: 14px;
  padding: 4px 0;
  text-align: center;
  @media screen and (min-width: 1024px) {
    display: none;
  }
  a {
    color: ${theme`colors.royalblue`};
    font-size: 14px;
  }
`

export const HeaderLogo = styled.img`
  ${tw`col-span-4`};
  width: 100%;
  max-width: 400px;
  @media screen and (min-width: 1024px) {
    width: 300px;
  }
  @media screen and (min-width: 1250px) {
    width: 400px;
  }
`

export const HeaderLinks = styled.div`
  ${tw`col-span-8`};
  display: none;

  @media screen and (min-width: 1024px) {
    align-items: center;
    display: flex;
    justify-content: space-between;

    a, p {
      color: ${theme`colors.royalblue`};
      font-size: 14px;
      transition: 0.4s;
    }
    a:hover, p:hover {
      cursor: pointer;
      color: ${theme`colors.orange`};
    }

    .hcp-only { 
      margin-left: 3em;
      text-align: center;
      @media screen and (min-width: 1024px) {
        margin-left: 0em;
        padding: 0 1em;
      }
      @media screen and (min-width: 1250px) {
        margin-left: 3em;
      }
      :hover {
        cursor: default;
        color: ${theme`colors.royalblue`};
      }
    }

    .multiple-links {
      align-items: center;
      display: flex;
      white-space: nowrap;
    }
`