import React from 'react'

import { Grid } from '../../../Blocks/Grid/styles'
import { LegalAgreementContainer, LegalAgreementCheckbox } from './styles'

const LegalAgreement = ({ onChange, onBlur, value }) => {
  return(
    <>
      <Grid>
        <LegalAgreementContainer>
          <p className="legalagreement-copy">By clicking the submit button below, you confirm that you have read and accept our <a href="https://www.type2congress.com/6d42c37b-050a-4f0f-8ed8-942264d42b96/de0f105c-62ea-4f8d-a17f-bcd8ec9d9b05/7c96efc1-9244-4322-bedd-e7d5a1dc09a7/docs/Legal-notice.pdf" target="_blank" rel="noreferrer" className="legalagreement-copy__anchor">Terms of Use</a> and <a href="https://www.sanofi.com/en/our-responsibility/sanofi-global-privacy-policy" target="_blank" rel="noreferrer" className="legalagreement-copy__anchor">Privacy Policy</a></p>
          <LegalAgreementCheckbox>
            <input
              type="checkbox"
              id="legalagreement"
              name="termsAccepted"
              required
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              />
            <label htmlFor="legalagreement">I understand and agree</label>
          </LegalAgreementCheckbox>
        </LegalAgreementContainer>
      </Grid>
    </>
  )
}

export default LegalAgreement 