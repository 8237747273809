import React from 'react'

import vmxBubble from '../../../../assets/images/vmx-bubble.png'
import arrowDownLarge from '../../../../assets/icons/arrow-down_mobile.png'

import { InformationContainer, VMXBubble, InformationTitle, InformationHeader, InformationCopy, ArrowDown } from './styles'

const Information = () => {
  const scrollDown = () => {
    window.scrollTo(0, 760);
  }
  return(
    <InformationContainer>
      <VMXBubble src={vmxBubble} alt="vmx-bubble" />

      <InformationTitle>Welcome to Sanofi Genzyme and Regeneron’s AAD VMX experience</InformationTitle>
      <InformationHeader>Platinum Sponsors at AAD VMX</InformationHeader>

      <InformationCopy>Regeneron is a leading biotechnology company that invents medicines for people with serious diseases.  Founded and led for over 30 years by physician-scientists, our unique ability to repeatedly and consistently translate science into medicine has led to nine FDA-approved treatments, all of which were homegrown in our laboratories.</InformationCopy>
      <InformationCopy>Sanofi is dedicated to supporting people through their health challenges. We are a global biopharmaceutical company focused on human health. We stand by the few who suffer from rare diseases and the millions with long-terms chronic conditions. With more than 100,000 people in 100 countries, Sanofi is transforming scientific innovation around the globe.</InformationCopy>

      <ArrowDown src={arrowDownLarge} alt="arrow-down_large" onClick={() => scrollDown()} />
    </InformationContainer>
  )
}

export default Information