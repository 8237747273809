import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

export const MedicalInformationContainer = styled.div`
  ${tw`col-span-4 order-7 lg:order-6`};
  align-items: center;
  background: ${theme`colors.royalblue`};
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  height: 70px;
  width: 250px;
  margin: 2em auto 3em auto;
  @media screen and (min-width: 350px) {
    width: 280px;
  }
  @media screen and (min-width: 1024px) {
    display: none;
    width: 300px;
  }
`

export const MedicalInformationPerson = styled.div`
  border-radius: 20px;
  height: 36px;
  width: 46px;
  position: relative;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const MedicalInformationBox = styled.div`
  color: ${theme`colors.white`};
  font-size: 24px;
  font-weight: 100;
  padding-left: 0.5em;
  padding-right: 0;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`