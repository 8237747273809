import React, { useState } from 'react';

import { theme } from 'twin.macro'

import { Grid } from '../../Blocks/Grid/styles'
import { IntroModalContainer, IntroModalOuter, IntroModalInner, IntroModalWelcome, IntroModalCopy, IntroModalDisclaimer, IntroModalBtns } from './styles'
import LargeBtn from '../../Blocks/Buttons/largeBtn'

const IntroModal = ({ setShowSite }) => {
  const cancelRedirect = () => {
    window.location.href = "https://www.sanofigenzyme.com/";
  }

  const sitePreviouslyVisited = () => {
    console.log("add site previously visited cookie storage");
  }

  return(
    <>
      <IntroModalContainer>
        <IntroModalOuter>
          <Grid>
            <IntroModalInner>
                <IntroModalWelcome>Welcome</IntroModalWelcome>
                <IntroModalCopy>This website is intended only for healthcare professionals and is not intended for the general public.</IntroModalCopy>
                <IntroModalCopy>The contents of this site has been developed to comply with US regulations. Prescribing information may vary depending on local approval in each country, therefore, before prescribing any product, always refer to your local prescribing information and/or the Summary of Product Characteristics (SmPC).</IntroModalCopy>
                <IntroModalCopy>Click “continue” only if you are a healthcare professional</IntroModalCopy>
                <IntroModalCopy>04/2021 DUP.21.03.0008</IntroModalCopy>
                <IntroModalBtns>
                  <LargeBtn color={theme`colors.white`} secondaryColor={theme`colors.orange`} buttonContent={"CANCEL"} onClick={() => cancelRedirect()} />
                  <LargeBtn color={theme`colors.orange`} buttonContent={"CONTINUE"} onClick={() => { setShowSite(true); sitePreviouslyVisited(); }} />
                </IntroModalBtns>
            </IntroModalInner>
          </Grid>
        </IntroModalOuter>
      </IntroModalContainer>
    </>
    
  )
}

export default IntroModal