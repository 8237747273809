import React from 'react'
import { theme } from 'twin.macro'

import sanofiRegeneronWhite from '../../assets/logos/sanofi-regeneron_white.png'

import { Section } from '../Blocks/Section/styles'
import { Grid } from '../Blocks/Grid/styles'
import { FooterTopSection, FooterJobCode } from './styles'

const Footer = () => {
  return(
    <>
      <Section color={theme`colors.bgRoyalBlue`}>
        <Grid>
          <FooterTopSection>
            <img src={sanofiRegeneronWhite} alt=""/>
            <p>&copy; 2021 Sanofi and Regeneron Pharmaceuticals, Inc. All Rights Reserved.</p>
          </FooterTopSection>
        </Grid>
      </Section>

      <Section color={theme`colors.bgDarkGrey`}>
        <Grid>
          <FooterJobCode>DUP.21.03.0008 <span style={{ marginLeft: "2em" }}>04/2021</span></FooterJobCode>
        </Grid>
      </Section>
    </>
  )
}

export default Footer