import styled from 'styled-components/macro';
import tw from 'twin.macro'

export const ContactFormContainer = styled.div`
  padding: 2rem 0;
`

export const ContactFormTitle = styled.div`
  ${tw`col-span-12`};
  color: white;
  text-align: center;

  .title-header {
    font-size: 26px;
    font-weight: bold;
    
    @media screen and (min-width: 1024px) {
      font-size: 30px;
    }
  }

  .title-copy {
    font-size: 16px;
    margin-top: 6px;

    @media screen and (min-width: 1024px) {
      font-size: 18px;
    }
  }
`