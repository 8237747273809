import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { theme } from 'twin.macro'

import person from '../../../assets/icons/person.png'
import personOrange from '../../../assets/icons/person-orange.png'

const MedicalInformationAnchor = styled.span`
    display: flex;
    align-items: center;
    :hover {
        p { 
            color: ${theme`colors.orange`};
            transition: 0s;
        }
    }
    p {
        margin-right: 5px;
    }
    img {
        width: 25px;
    }
`

const MedicalInformation = ({ setShowExitModal, setExitUrl, setExitModalCopy }) => {
    const [hovering, setHovering] = useState(false)

    return(
        <MedicalInformationAnchor onClick={() => {
            setShowExitModal(true)
            setExitUrl("https://www.regeneron.com/medical-inquiries")
            setExitModalCopy("You are being redirected to the medical inquiries page of Regeneron Pharmaceuticals Inc.")
            }} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            <p>Medical Information</p>
            {!hovering ? (
                <img src={person} alt="person" />
            ) : (
                <img src={personOrange} alt="person-orange" />
            )}
        </MedicalInformationAnchor>
    )
}

export default MedicalInformation