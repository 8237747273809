import styled from 'styled-components/macro'
import tw from 'twin.macro'

export const PopupModalContainer = styled.div`
  background: rgba(0, 0, 0, 0.7); /* black with 70% opacity */
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  display: ${props => props.showModal ? "block" : "none"};
  height: 100vh;
`

export const PopupModalInner = styled.div`
  ${tw`col-span-4 lg:col-span-12`};
  align-items: center;
  background: white;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 3em 1em;
  width: calc(100% - 2em);
  @media screen and (min-width: 1024px) {
    padding: 3em;
    width: calc(100% - 6em);
  }
`

export const PopupModalIFrame = styled.iframe`
`

export const PopupModalClose = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0.75em;
  top: 0.75em;
  width: 30px;
`

export const PopupModalPDFObject = styled.iframe`
  width: 100%;
  height: 100%;
`