import React, { useState } from 'react'
import styled from 'styled-components/macro';
import { theme } from 'twin.macro'

import envelope from '../../../assets/icons/envelope.png'
import envelopeOrange from '../../../assets/icons/envelope-orange.png'

const KeepInTouchAnchor = styled.a`
    display: flex;
    align-items: center;
    margin: 0 1.25em;
    @media screen and (min-width: 1150px) {
        margin: 0 1.75em;
    }
    :hover {
        p { 
            color: ${theme`colors.orange`};
            transition: 0s;
        }
    }
    p {
        margin-right: 5px;
    }
    img {
        width: 25px;
    }
`

const KeepInTouch = () => {
    const [hovering, setHovering] = useState(false)

    return(
        <KeepInTouchAnchor href="#contact-form" onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            <p>Keep in touch</p>
            {!hovering ? (
                <img src={envelope} alt="envelope" />
            ) : (
                <img src={envelopeOrange} alt="envelope-orange" />
            )}
        </KeepInTouchAnchor>
    )
}

export default KeepInTouch