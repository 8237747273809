import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

export const InformationContainer = styled.div`
  ${tw`col-span-4 order-1 lg:row-span-2 lg:col-span-4 lg:order-2`};
  position: relative;
  text-align: center;
`

export const VMXBubble = styled.img`
  display: block;
  margin: 0 auto;
  width: 140px;

  @media screen and (min-width: 1024px) {
    width: 230px;
  }
`

export const InformationTitle = styled.p`
  border-bottom: 2px dotted ${theme`colors.orange`};
  color: ${theme`colors.royalblue`};
  font-size: 30px;
  margin-top: 0.2em;
  padding-bottom: 0.25em;
  
  @media screen and (min-width: 1100px) {
    padding-bottom: 0.5em;
  }
`

export const InformationHeader = styled.p`
  color: ${theme`colors.blue`};
  font-size: 26px;
  font-weight: 100;
  margin: 0.5em 0;
  @media screen and (min-width: 1100px) {
    font-size: 28px;
    margin: 0.5em 0;
  }
`

export const InformationCopy = styled.p`
  color: ${theme`colors.darkgrey`};
  font-size: 15px;
  margin-top: 0.5em;
`

export const ArrowDown = styled.img`
  display: block;
  margin: 3em auto 2em auto;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`