import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

export const IntroModalContainer = styled.div`
  background: ${theme`colors.bgLightBlue`};
  height: 100%;
  position: absolute;
  width: 100vw;
`

export const IntroModalOuter = styled.div`
  align-items: center;
  display: flex;
  background: ${theme`colors.bgLightBlue`};
  height: 100%;
  width: 100vw;
`

export const IntroModalInner = styled.div`
  ${tw`col-span-4 lg:col-span-6 lg:col-start-4`};
  background: ${theme`colors.bgRoyalBlue`};
  border-radius: 12px;
  box-shadow: 0 0 12px 8px #ccc;
  max-width: calc(100% - 2em);
  min-height: 200px;
  padding: 1.5em 1em;
  position: relative;
  width: 100%;
  @media screen and (min-width: 550px) {
    padding: 1.5em 1em 3.5em 1em;
  }
  @media screen and (min-width: 768px) {
    max-width: 600px;
  }
`

export const IntroModalWelcome = styled.p`
  color: ${theme`colors.skyblue`};
  font-size: 32px;
  font-weight: 100;
  text-align: center;
  
  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
`

export const IntroModalCopy = styled.p`
  color: ${theme`colors.white`};
  font-size: 16px;
  font-weight: 100;
  margin-top: 1em;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
`

export const IntroModalDisclaimer = styled.p`
  color: ${theme`colors.skyblue`};
  font-size: 14px;
  font-weight: 100;
  margin-top: 1em;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`

export const IntroModalBtns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1em auto 0 auto;
  position: relative;
  width: calc(100% - 2em);
  @media screen and (min-width: 550px) {
    bottom: -22px;
    flex-wrap: nowrap;
    position: absolute;
  }
  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
  button {
    margin: 0.25em 0.5em;
    @media screen and (min-width: 550px) {
      margin: 0 1em;
    }
  }
`