import styled from 'styled-components/macro';
import tw, { theme } from 'twin.macro'

import arrowDown from '../../../assets/icons/arrow-down.png'

export const FormContainer = styled.form`
  input, select, textarea {
    ::placeholder {
      color: ${theme`colors.darkgrey`};
    }
    color: ${theme`colors.darkgrey`};
    font-family: 'Roboto', sans-serif;
    padding: 12px;
  }
`

export const InputLeft = styled.input`
  ${tw`col-span-4 lg:col-span-4 lg:col-start-3`};
`

export const ErrorMessage = styled.p`
  ${tw`col-span-8 col-start-3 hidden lg:block`}
  color: ${theme`colors.orange`};
`

export const ErrorMessageMobile = styled.p`
  ${tw`col-span-4 lg:hidden`}
  color: ${theme`colors.orange`};
`

export const SubmitMessage = styled.p`
  ${tw`col-span-4 lg:col-span-12 text-2xl font-bold`};
  margin: 0 auto;
  color: ${theme`colors.white`};
`

export const InputRight = styled.input`
  ${tw`col-span-4 lg:col-span-4 lg:col-end-11`};
`

export const SelectRight = styled.select`
  ${tw`col-span-4 lg:col-span-4 lg:col-end-11`};
  appearance: none;
  background: url(${arrowDown}) calC(100% - 12px) no-repeat #fff;
  background-size: 16px 10px;
`

export const TextAreaFull = styled.textarea`
  ${tw`col-span-4 lg:col-span-8 lg:col-start-3`};
  height: 100px;
`

export const SubmitButton = styled.div`
  ${tw`col-span-4 lg:col-span-12`};
  margin: 0 auto;
`