import React, { useState } from 'react'
import { theme } from 'twin.macro'
import { Section } from './components/Blocks/Section/styles'

import IntroModal from './components/Modals/IntroModal/introModal'
import Header from './components/Header/header'
import TopSection from './components/TopSection/topSection'
import ContactForm from './components/ContactForm/contactForm'
import Footer from './components/Footer/footer'
import PopupModal from './components/Modals/PopupModal/popupModal'
import ExitModal from './components/Modals/ExitModal/exitModal'

import TagManager from 'react-gtm-module'

process.env.REACT_APP_GTM_ID && TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID })

function App() {
  const [showModal, setShowModal] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)
  const [showSite, setShowSite] = useState(false)
  const [pdf, setPdf] = useState('')
  const [exitUrl, setExitUrl] = useState('')
  const [exitModalCopy, setExitModalCopy] = useState('')
  return (
    <div className="App">
      {!showSite && <IntroModal setShowSite={setShowSite} />}

      {showSite &&
        <>
          <Header setShowModal={setShowModal} setShowExitModal={setShowExitModal} setPdf={setPdf} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy} />

          <Section color={theme`colors.bgLightBlue`}>
            <TopSection setShowModal={setShowModal} setShowExitModal={setShowExitModal} setPdf={setPdf} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy} />
          </Section>

          <Section color={theme`colors.bgGrey`}>
            <ContactForm setShowExitModal={setShowExitModal} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy} />
          </Section>

          <Footer />

          {showModal &&
            <PopupModal showModal={showModal} setShowModal={setShowModal} pdf={pdf} />
          }
          {showExitModal &&
            <ExitModal showExitModal={showExitModal} setShowExitModal={setShowExitModal} exitUrl={exitUrl} exitModalCopy={exitModalCopy} />
          }
        </>
      }
    </div>
  );
}

export default App; 
