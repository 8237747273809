import React from 'react'
import styled from 'styled-components/macro';
import tw from 'twin.macro'

import envelopeBubble from '../../../assets/icons/envelope-bubble.png'

const EnvelopeContainer = styled.div`
  ${tw`col-span-12`}
`

const EnvelopeImg = styled.img`
  margin: 0 auto;
  display: block;
`

const EnvelopeIcon = () => {
  return(
    <EnvelopeContainer>
      <EnvelopeImg src={envelopeBubble} alt="envelope-bubble" />
    </EnvelopeContainer>
  )
}

export default EnvelopeIcon