import React, { useState, useRef } from 'react'

import { Grid } from '../../Blocks/Grid/styles'
import { PopupModalContainer, PopupModalInner, PopupModalClose, PopupModalPDFObject } from './styles'

import useLockBodyScroll from '../../../utils/useLockBodyScroll'

import closeBtn from '../../../assets/icons/close.png'

const PopupModal = ({ showModal, setShowModal, pdf }) => {
  const [status, resetPdfStatus] = useState(false)
  const pdfRef = useRef(null)
  useLockBodyScroll();

  return(
    <PopupModalContainer showModal={showModal}>
      <Grid style={{ height: "90vh", paddingTop: "5vh" }}>
        <PopupModalInner>
          <PopupModalPDFObject
            ref={pdfRef}
            src={`/pdf/web/viewer.html?file=${pdf}`}
            width="100%"
            onLoad={() => resetPdfStatus(!status)}
          />
          <PopupModalClose src={closeBtn} alt="close-btn" onClick={() => setShowModal(false)} />
        </PopupModalInner>
      </Grid>
    </PopupModalContainer>
  )
}

export default PopupModal