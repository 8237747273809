import React, { useState } from 'react'
import { theme } from 'twin.macro'

import { ProductContainer, ProductShape, ProductText } from './styles'
import ArrowOrange from '../../../Blocks/Buttons/arrowOrange'

const Product = ({ setShowModal, setPdf }) => {
  const [hovering, setHovering] = useState(false)

  return(
    <ProductContainer onClick={() => {
      setShowModal(true)
      setPdf("https://uploads.aad-lobby.hlxcongress.digital/assets/pdfs/Dupixent_AAD_IES_Invitation.pdf")}}
      onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}
      >
      <ProductShape hovering={hovering} />
      <ProductText hovering={hovering}>Visit our non-CME product theatre</ProductText>
      <ArrowOrange hovering={hovering} textColor={theme`colors.orange`} hoverColor={theme`colors.white`}  />
    </ProductContainer>
  )
}

export default Product