import React from 'react'

import sanofiRegeneronLogo from '../../assets/logos/sanofi-regeneron.png'

import { Grid } from '../Blocks/Grid/styles'
import { HeaderContainer, HeaderDisclaimer, HeaderLogo, HeaderLinks } from './styles'
import KeepInTouch from './KeepInTouch/keepInTouch'
import MedicalInformation from './MedicalInformation/medicalInformation';

const Header = ({ setShowExitModal, setExitUrl, setExitModalCopy }) => {
  return(
    <>
      <HeaderDisclaimer>
        <a href="#">This website is for healthcare professionals only</a>
      </HeaderDisclaimer>
      
      <HeaderContainer>
        <Grid>
          <HeaderLogo src={sanofiRegeneronLogo} />
          <HeaderLinks>
            <p className="hcp-only">This website is for healthcare professionals only</p>
            <div className="multiple-links">
              <KeepInTouch />
              <MedicalInformation setShowExitModal={setShowExitModal} setExitUrl={setExitUrl} setExitModalCopy={setExitModalCopy} />
            </div>
          </HeaderLinks>
        </Grid>
      </HeaderContainer>
    </>
  )
}

export default Header