import React, { useState } from 'react'
import styled from 'styled-components/macro'

const LargeButtonContainer = styled.button`
  background: ${props => props.color};
  border-radius: 23px;
  border: none;
  box-shadow: 0px 4px 4px 0px #2D3742;
  cursor: pointer;
  color: ${props => props.secondaryColor ? `${props.secondaryColor}` : "white"};
  min-height: 46px;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  outline: none;
  padding: 0 3rem;
  :hover {
    transform: scale(1.02);
    transition: 0.2s;
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.3;
    :hover {
      transform: none;
    }
  }
`

const LargeButton = ({ buttonContent, color, secondaryColor, onClick, disabled = false }) => {
  return(
    <LargeButtonContainer color={color} secondaryColor={secondaryColor} onClick={onClick} disabled={disabled}>
      {buttonContent}
    </LargeButtonContainer>
  )
}

export default LargeButton