import React from 'react'

import { MedicalInformationContainer, MedicalInformationPerson, MedicalInformationBox } from './styles'
import arrowOrange from '../../../../assets/icons/arrow-right_orange.png'

const MedicalInformation = ({ setShowExitModal, setExitUrl, setExitModalCopy }) => {

  return(
    <MedicalInformationContainer onClick={() => {
      setShowExitModal(true)
      setExitUrl("https://www.regeneron.com/medical-inquiries")
      setExitModalCopy("You are being redirected to the medical inquiries page of Regeneron Pharmaceuticals Inc.")
      }}>
      <MedicalInformationBox>
        <p>Medical Information</p>
      </MedicalInformationBox>

      <MedicalInformationPerson>
        <img src={arrowOrange} alt="" width="40px" height="40px" />
      </MedicalInformationPerson>
    </MedicalInformationContainer>
  )
}

export default MedicalInformation