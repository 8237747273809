import styled from 'styled-components/macro'
import tw from 'twin.macro'

export const Grid = styled.div`
  ${tw`grid grid-cols-4 lg:grid-cols-12 gap-4`};
  max-width: 90%;
  margin: 0 auto;
  @media screen and (min-width: 1024px) {
    max-width: 1200px;
    width: 90%;
  }
`