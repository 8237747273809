import React from 'react'
import styled from 'styled-components/macro'

import { theme } from 'twin.macro'

import arrowRightWhite from '../../../assets/icons/arrow-right_white.png'

const ArrowOrangeContainer = styled.div`
  align-items: center;
  background: ${props => props.hovering ? props.hoverColor : theme`colors.orange`};
  border-radius: 35px;
  bottom: 1em;
  box-shadow: 2px 2px 10px 2px ${theme`colors.grey`};
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: center;
  position: absolute;
  width: 52px;
  transition: 0.5s;
  @media screen and (min-width: 1024px) {
    left: 50%;
    transform: translateX(-50%);
  }

  img { 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    color: ${props => props.textColor};
    font-size: 24px;
    font-weight: bold;
  }
`

const arrowOrange = ({ hovering, hoverColor, textColor }) => {
  return(
    <ArrowOrangeContainer hovering={hovering} hoverColor={hoverColor} textColor={textColor}>
      {!hovering ? (
        <img src={arrowRightWhite} alt="arrow-right_white"/>
      ) : (
        <p>GO</p>
      )}
    </ArrowOrangeContainer>
  )
}

export default arrowOrange