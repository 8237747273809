import styled from 'styled-components/macro';
import { theme } from 'twin.macro'

export const ShowInMobile = styled.div`
  display: block;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const ShowInDesktop = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`

export const TopSectionContainer = styled.div`
  .topsection_grid {
    position: relative;
    @media screen and (max-width: 1023px) {
      gap: 0;
      padding: 1rem 1rem 0 1rem;
      margin: 0;
      max-width: 100%;
    }
    &.skyblue {
      background: ${theme`colors.bgLightBlue`};
    }
    &.white {
      background: ${theme`colors.white`};
    }
    &.grey {
      background: ${theme`colors.bgMediumGrey`};
    }
  }
`